/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './config/apollo';

import Routes from './routes/routes';
import { theme } from './theme';
import './theme/global.css';

function App() {
    const engine = new Styletron();

    return (
        <ApolloProvider client={client as any}>
            <StyletronProvider value={engine}>
                <BaseProvider theme={theme}>
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </BaseProvider>
            </StyletronProvider>
        </ApolloProvider>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));
