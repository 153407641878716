/** @format */

import React, { lazy, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/routes';
import { AuthContext } from 'src/context/auth';

import { BOOKINGS, PAST_BOOKINGS } from 'src/settings/constants';

const Bookings = lazy(() => import('src/containers/Bookings/Bookings'));
const PastBookings = lazy(() => import('src/containers/PastBookings/PastBookings'));
const NotFound = lazy(() => import('src/containers/NotFound/NotFound'));

const MemberRoutes = (_: any) => {
    const { getAuthentication } = useContext(AuthContext);
    const { isMember } = getAuthentication();
    if (!isMember) {
        return (
            <PrivateRoute>
                <Route component={NotFound} />
            </PrivateRoute>
        );
    }

    return (
        <Switch>
            <Redirect exact from="/member/" to={`member${BOOKINGS}`} />
            <PrivateRoute path={`/member${BOOKINGS}`}>
                <Bookings />
            </PrivateRoute>
            <PrivateRoute path={`/member${PAST_BOOKINGS}`}>
                <PastBookings />
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>
    );
};

export default MemberRoutes;
