/** @format */

import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

type AuthProps = {
    isAuthenticated: boolean;
    getAuthentication: Function;
    authenticate: Function;
    signOut: Function;
};

export const AuthContext = createContext({} as AuthProps);

const isValidToken = () => {
    const token = localStorage.getItem('token');
    if (token) return true;
    return false;
};

export const getTimeout = () => {
    const { time } = JSON.parse(localStorage.getItem('user')) || {};
    if (!time) return true;

    const formatTime = time.replace(/(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1-$2-$3 $4:$5');
    const diff = (new Date().getTime() - new Date(formatTime).getTime()) / 60000;
    if (diff > 30) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    return diff > 30;
};

const AuthProvider = (props: any) => {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());

    function getAuthentication() {
        return JSON.parse(localStorage.getItem('user') || '{}');
    }

    function getDefaultRouteByRole(role: string) {
        // Assume role is strictly defined, SUPERADMIN, USER, and ABC. ABC is any string which defines name of resource group in upper case.
        // User with this ABC role is assumed to be at least one resource group admin.
        // And the sequence of checking matters. Since user can be multiple role, so SUPERADMIN > ABC > USER
        //  - Fernando 2 June 2020
        if (role === 'SUPERADMIN') return '/admin'; 
        if (role != null && role !== '' && role !== 'SUPERADMIN' && role !== 'USER') return '/admin';  
        if (role === 'USER') return '/member';
        if (role === 'TEMPLATE') return '/template';
        return '/error';
    }

    function setStorage(credential: any) {
         // Assume role is strictly defined, SUPERADMIN, USER, and ABC. ABC is any string which defines name of resource group in upper case.
        // User with this ABC role is assumed to be at least one resource group admin.
        // And the sequence of checking matters. Since user can be multiple role, so SUPERADMIN > ABC > USER
        //  - Fernando 2 June 2020
        const { role, jwttoken } = credential;
        localStorage.setItem('token', jwttoken);
        localStorage.setItem(
            'user',
            JSON.stringify({
                ...credential,
                isAdmin: role.toLowerCase().includes('superadmin') || (role.toLowerCase() !== 'superadmin' && role.toLowerCase() !== 'user'),
                isMember: role.toLowerCase().includes('user'),
            })
        );
    }

    function authenticate(credential: any) {
        const origin = getDefaultRouteByRole(credential.role);

        // IMPORTANT
        if (origin === '/error') {
            history.push(origin);
            return;
        }

        setStorage({ ...credential, origin });
        setIsAuthenticated(true);
        history.push(origin);
    }

    function signOut() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        history.push('/logout');
        setIsAuthenticated(false);
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                getAuthentication,
                authenticate,
                signOut,
            }}>
            <>{props.children}</>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
