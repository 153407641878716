/** @format */

// **************** ROUTE CONSTANT START **************************

// RESOURCE-BOOKING
export const AUTH = '/auth';
export const UNAUTHENTICATED = '/unauthenticated';
export const STAFFPORTAL = '/staff-portal';
export const LOGOUT = '/logout';
export const CALENDAR_AUTH = '/calendar-auth';

export const RESOURCES = '/resources';
export const BOOKINGS = '/bookings';
export const PAST_BOOKINGS = '/pastbookings';
export const BOOKING_QUEUE = '/bookingqueue';

export const SETTINGS = '/settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const RESOURCE_GROUPS = '/resource-groups';
export const AUDIT_LOGS = '/audit-logs';

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
