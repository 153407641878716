/** @format */

import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { AUTH, STAFFPORTAL, UNAUTHENTICATED, CALENDAR_AUTH } from 'src/settings/constants';

import AuthProvider, { AuthContext } from 'src/context/auth';
import { InlineLoader } from 'src/components/InlineLoader/InlineLoader';
import MemberRoutes from './MemberRoutes';
import AdminRoutes from './AdminRoutes';

const AdminLayout = lazy(() => import('src/containers/Layout/Layout'));
const Auth = lazy(() => import('src/containers/Auth/Auth'));
const Logout = lazy(() => import('src/containers/Logout/Logout'));
const StaffPortal = lazy(() => import('src/containers/StaffPortal/StaffPortal'));
const Unauthenticated = lazy(() => import('src/containers/Unauthenticated/Unauthenticated'));
const NotFound = lazy(() => import('src/containers/NotFound/NotFound'));
const CalendarAuth = lazy(() => import('src/containers/CalendarAuth/CalendarAuth'));

const { REACT_APP_ENV } = process.env;

/**
 * A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 */
export function PrivateRoute({ children, ...rest }) {
    const { isAuthenticated } = useContext(AuthContext);

    const render = ({ location }) => {
        if (isAuthenticated) {
            return (
                <AdminLayout>
                    <Suspense fallback={<InlineLoader />}>{children}</Suspense>
                </AdminLayout>
            );
        }

        return (
            <Redirect
                to={{
                    pathname: '/',
                    state: { from: location },
                }}
            />
        );
    };

    return <Route {...rest} render={render} />;
}

const Routes = () => {
    return (
        <AuthProvider>
            <Suspense fallback={<InlineLoader />}>
                <Switch>
                    <Route exact path="/" component={Auth} />
                    <Route exact path={UNAUTHENTICATED} component={Unauthenticated} />
                    <Route path={CALENDAR_AUTH} component={CalendarAuth} />
                    <Route path="/member" component={MemberRoutes} />
                    <Route path="/admin" component={AdminRoutes} />
                    <Route exact path="/logout" component={Logout} />
                    {/* temporarily allows production, during UAT */}
                    {REACT_APP_ENV !== 'production' &&
                    REACT_APP_ENV !== 'development' &&
                    REACT_APP_ENV !== 'indie' ? null : (
                        <Route exact path={STAFFPORTAL} component={StaffPortal} />
                    )}
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </AuthProvider>
    );
};

export default Routes;
