/** @format */

import React, { lazy, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/routes';
import { AuthContext } from 'src/context/auth';

import {
    RESOURCES,
    BOOKINGS,
    PAST_BOOKINGS,
    BOOKING_QUEUE,
    SETTINGS,
    STAFF_MEMBERS,
    RESOURCE_GROUPS,
    AUDIT_LOGS,
    CALENDAR_AUTH,
} from 'src/settings/constants';

const Resources = lazy(() => import('src/containers/Resources/Resources'));
const Bookings = lazy(() => import('src/containers/Bookings/Bookings'));
const PastBookings = lazy(() => import('src/containers/PastBookings/PastBookings'));
const NotFound = lazy(() => import('src/containers/NotFound/NotFound'));

const Settings = lazy(() => import('src/containers/Settings/Settings'));
const StaffMembers = lazy(() => import('src/containers/StaffMembers/StaffMembers'));
const ResourceGroups = lazy(() => import('src/containers/ResourceGroups/ResourceGroups'));
const BookingQueue = lazy(() => import('src/containers/BookingsQueue/BookingsQueue'));
const AuditLogs = lazy(() => import('src/containers/AuditLogs/AuditLogs'));
// const CalendarAuth = lazy(() => import('src/containers/CalendarAuth/CalendarAuth'));

const AdminRoutes = (_: any) => {
    const { getAuthentication } = useContext(AuthContext);
    const { isAdmin } = getAuthentication();
    if (!isAdmin) {
        return (
            <PrivateRoute>
                <Route component={NotFound} />
            </PrivateRoute>
        );
    }

    return (
        <Switch>
            <Redirect exact from={'/admin/'} to={`admin${BOOKINGS}`} />
            <PrivateRoute path={`/admin${RESOURCES}`}>
                <Resources />
            </PrivateRoute>
            <PrivateRoute path={`/admin${BOOKINGS}`}>
                <Bookings />
            </PrivateRoute>
            <PrivateRoute path={`/admin${PAST_BOOKINGS}`}>
                <PastBookings />
            </PrivateRoute>
            <PrivateRoute path={`/admin${SETTINGS}`}>
                <Settings />
            </PrivateRoute>
            <PrivateRoute path={`/admin${STAFF_MEMBERS}`}>
                <StaffMembers />
            </PrivateRoute>
            <PrivateRoute path={`/admin${RESOURCE_GROUPS}`}>
                <ResourceGroups />
            </PrivateRoute>
            <PrivateRoute path={`/admin${BOOKING_QUEUE}`}>
                <BookingQueue />
            </PrivateRoute>
            <PrivateRoute path={`/admin${AUDIT_LOGS}`}>
                <AuditLogs />
            </PrivateRoute>
            {/* <Route path={`/admin${CALENDAR_AUTH}`}>
                <CalendarAuth />
            </Route> */}
            <Route component={NotFound} />
        </Switch>
    );
};

export default AdminRoutes;
